.paper-tile {
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #fbfbfb;
}

.paper {
  font-size: smaller;
}

.display_none {
  display: none;
}

.download-icon > svg {
  vertical-align: bottom;
}
