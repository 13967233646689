//
// Copyright IBM Corp. 2016, 2018
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

@import 'helper-classes';
@import './vendor/@carbon/elements/scss/layout/layout';

/// @access private
/// @type Map
/// @group global-layout
$z-indexes: (
  // Dropdowns that render outside of a Modal should render above a Modal.
  // Dropdowns below the modal will close when the Modal is opened, so
  // having a higher z-index *should* not cause issues.
  dropdown: 9100,
  modal: 9000,
  header: 8000,
  overlay: 6000,
  floating: 6000,
  footer: 5000,
  hidden: - 1,
  overflowHidden: - 1
);

/// @access public
/// @param {String} $layer - Value from `$z-indexes` map
/// @group global-layout
/// @example - scss
///   .modal {
///     z-index: z('modal');
///   }
@function z($layer) {
  @if not map-has-key($z-indexes, $layer) {
    @warn 'No layer found for `#{$layer}` in $z-indexes map. Property omitted.';
  }

  @return map-get($z-indexes, $layer);
}
