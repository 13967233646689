//
// Copyright IBM Corp. 2016, 2018
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

@import '../../globals/scss/helper-mixins';
@import '../../globals/scss/vendor/@carbon/elements/scss/import-once/import-once';
@import '../../globals/scss/vars';

@include exports('data-table-inline-edit') {
  .#{$prefix}--inline-edit-label {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      .#{$prefix}--inline-edit-label__icon {
        opacity: 1;
      }
    }
  }

  .#{$prefix}--inline-edit-label--inactive {
    display: none;
  }

  .#{$prefix}--inline-edit-label__action {
    @include button-reset(false);

    &:hover {
      cursor: pointer;
    }

    &:focus {
      @include focus-outline;

      padding: $spacing-01;

      .#{$prefix}--inline-edit-label__icon {
        width: auto;
        opacity: 1;
      }
    }
  }

  .#{$prefix}--inline-edit-label__icon {
    fill: $icon-primary;
    opacity: 0;
  }

  .#{$prefix}--inline-edit-input {
    display: none;
  }

  .#{$prefix}--inline-edit-input--active {
    display: block;
    margin-left: rem(-12px);

    input {
      padding-left: $spacing-04;
    }
  }
}
