//
// Copyright IBM Corp. 2016, 2018
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

@import 'vars';
@import 'functions';

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate-end-p1 {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate-end-p2 {
  100% {
    transform: rotate(-360deg);
  }
}

/* Stroke animations */
@keyframes init-stroke {
  0% {
    stroke-dashoffset: loading-progress($circumference, 0);
  }
  100% {
    stroke-dashoffset: loading-progress($circumference, 81);
  }
}

@keyframes stroke-end {
  0% {
    stroke-dashoffset: loading-progress($circumference, 81);
  }
  100% {
    stroke-dashoffset: loading-progress($circumference, 0);
  }
}
