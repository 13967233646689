.text-blue {
  color: #0f62fe;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.sidebar {
}

.bx--grid--full-width {
  padding: 0;
}

.bx--container {
  padding: 20px;
}

.footer {
  position: fixed;
  bottom: 0;
  font-size: smaller;
}

.bonus-link {
  box-shadow: none !important;
  width: fit-content !important;
}

.bonus-link path {
  fill: #0f62fe !important;
}

.buttonset {
  box-shadow: none !important;
}

.tertiary-danger {
  border-color: red;
  color: red;
}

.tertiary-danger:hover {
  background-color: red;
}

.tertiary-secondary {
  border-color: black;
  color: black;
}

.tertiary-secondary:hover {
  background-color: black;
}

.see-also-accordion .bx--accordion__content {
  padding: 0;
  margin: 0;
}
