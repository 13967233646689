//
// Copyright IBM Corp. 2018, 2018
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//
//-------------------------------------------
// Compatibility notes
// ------------------------------------------
//
// This file is intended to be consumed and processed with node-sass/libsass.
// Sass language features only available in dart-sass, such as `math.div`,
// should not be used.
//
// The `.import` suffixed version of this file eg. `_filename.import.scss`
// is intended to be compatible with dart-sass.
//
// Styles authored within this file must be duplicated to the corresponding
// compatibility file to ensure we continue to support node-sass and dart-sass
// in v10.

/// Default font size
/// @type Number
/// @access public
/// @group @carbon/layout
$carbon--base-font-size: 16px !default;

/// Convert a given px unit to a rem unit
/// @param {Number} $px - Number with px unit
/// @return {Number} Number with rem unit
/// @access public
/// @group @carbon/layout
@function carbon--rem($px) {
  @if unit($px) != 'px' {
    // TODO: update to @error in v11
    @warn "Expected argument $px to be of type `px`, instead received: `#{unit($px)}`";
  }

  @return ($px / $carbon--base-font-size) * 1rem;
}

/// Convert a given px unit to a em unit
/// @param {Number} $px - Number with px unit
/// @return {Number} Number with em unit
/// @access public
/// @group @carbon/layout
@function carbon--em($px) {
  @if unit($px) != 'px' {
    // TODO: update to @error in v11
    @warn "Expected argument $px to be of type `px`, instead received: `#{unit($px)}`";
  }

  @return ($px / $carbon--base-font-size) * 1em;
}
