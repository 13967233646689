//
// Copyright IBM Corp. 2021
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

@import '../../globals/scss/vars';
@import '../../globals/scss/helper-mixins';
@import '../../globals/scss/vendor/@carbon/elements/scss/import-once/import-once';

/// Progress Bar styles
/// @access private
/// @group progress-bar
@mixin progress-bar {
  .#{$prefix}--progress-bar__label {
    @include type-style('body-short-01');

    display: block;
    margin-bottom: $spacing-03;
    color: $text-primary;
  }

  .#{$prefix}--progress-bar__track {
    position: relative;
    width: 100%;
    height: rem(8px);
    background-color: $layer;
  }

  .#{$prefix}--progress-bar__bar {
    display: block;
    width: 100%;
    height: 100%;
    background-color: $interactive;
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition: transform $duration--fast-02 motion(standard, productive);
  }

  .#{$prefix}--progress-bar--indeterminate
    .#{$prefix}--progress-bar__track::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    animation-duration: 1400ms;
    animation-iteration-count: infinite;
    animation-name: progress-bar-indeterminate;
    animation-timing-function: linear;
    background-image: linear-gradient(
      90deg,
      $interactive 12.5%,
      transparent 12.5%
    );
    background-position-x: 0%;
    background-size: 200% 100%;
    content: '';
  }

  .#{$prefix}--progress-bar__helper-text {
    @include type-style('helper-text-01');

    margin-top: $spacing-02;
    color: $text-secondary;
  }

  @keyframes progress-bar-indeterminate {
    0% {
      background-position-x: 25%;
    }

    80%,
    100% {
      background-position-x: -105%;
    }
  }
}

@include exports('progress-bar') {
  @include progress-bar;
}
